.MainContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: #fff;
    height: 95vh;
    border-radius: 35px;
    width: 98%;
    padding: 3%;
    gap: 30px;
  }
  .MainContainer::-webkit-scrollbar {
    display: none;
  }
  .container1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-left: 40px;
  }
  .container1 span {
    color: gray;
    font-size: 20px;
  }
  .container1 select {
    background-color: #ecf1f4;
    height: 60px;
    border-radius: 16px;
    display: flex;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .inputGroup {
    border: none;
    background-color: #ecf1f4;
    height: 60px;
    border-radius: 16px;
    display: flex;
    align-items: center;
  }
  .inputGroup input {
    border: none;
    outline: none;
    border-right: 1px solid gray;
  }
  .inputGroup input:focus {
    outline: none !important;
  }
  .sideItem {
    border: none !important;
  }
  .btn{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding-top: 40px;
  }
  
  .btn button{
    height: 50px;
    width: 140px;
    border-radius: 16px;
  }
.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
}

.container {
  display: grid;
  grid-template-columns: 40% 60%;
}

.container > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: bold;
  padding: 20px;
}

.container > div:nth-child(2) {
  border-left: 1px solid var(--primary-color);
}

.container > div:nth-child(1) select {
  height: 50px;
  background-color: var(--primary-color);
  font-weight: bold;
}

.toggle {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  padding: 15px;
  border-radius: 8px;
}

.mainContainer .container .resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
}

.resetPassword input {
  background-color: #ecf1f4;
  height: 59.9px;
  border-radius: 16px;
  outline: none;
}

.resetPassword input::placeholder {
  font-weight: 600;
  color: black;
}

.submit {
  background-color: #333333;
  color: #fff;
  height: 50px;
  border-radius: 25px;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .MainContainer {
    padding: 20px;
  }
  .container > div {
    padding: 10px;
  }
  .firstDiv {
    font-size: 18px !important;
    padding: 14px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    display: block;
  }

  .container > div:nth-child(2) {
    border-left: none;
    border-top: 1px solid var(--primary-color);
  }
}

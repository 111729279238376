@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --max-width: 1100px;
  --primary-color: #d9d9d9;
  --primary-btn-color: #333333;
  --poppins-font: "Poppins", sans-serif;
  --quickSand--font: "Quicksand", sans-serif;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
}

.google-visualization-chart {
  padding: 0;
  /* Remove padding for the chart container */
}

.ant-picker-body {
  background-color: #f3f3f3;
  border-radius: 0 0 18px 18px !important;
}

.ant-picker-calendar {
  background-color: #f3f3f3;
  border-radius: 18px !important;
}

.google-visualization-legend {
  font-size: 14px;
  /* Change font size */
  font-weight: bold;
  /* Add bold style */
  color: #333;
  /* Change font color */
  /* Add any other styles you want to apply to the legend */
}

/* Style the legend items */
.google-visualization-legend-item {
  cursor: pointer;
  /* Add cursor pointer on hover */
  /* Add any other styles you want to apply to the legend items */
}


.css-1sibkpi[aria-checked=true],
.css-1sibkpi[data-checked] {
  --switch-bg: var(--chakra-colors-teal-200) !important;
}

.ant-select-selector {
  border: none !important;
}

.ant-select-selector:hover {
  border: none !important;
}

.ant-select-clear {
  color: red !important;
}

.ant-btn-icon {
  color: red;
}


.serachSelect .ant-select-selector {
  height: 60px !important;
  background: #ecf1f4 !important;
  border-radius: 14px !important;
  border: 1px solid #e2e8f0 !important;
  align-items: center !important;
}

.serachSelect .ant-select-selector .ant-select-selection-placeholder {
  font-size: 16px;
  color: gray;
}

.serachSelect .ant-select-selector .ant-select-selection-item {
  font-size: 16px;
  color: black;
}


.create-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.font-bold {
  font-weight: bold;
}

.text-lg {
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  /* 18px */
}

.md\:text-base {
  font-size: 1rem;
  /* 16px */
}

a.cursor-pointer {
  cursor: pointer;
}

.text-gray-500 {
  color: #6B7280;
  /* Tailwind CSS gray-500 */
}

.hover\:text-gray-800:hover {
  color: #1F2937;
  /* Tailwind CSS gray-800 */
}

.mx-2 {
  margin-left: 0.5rem;
  /* 8px */
  margin-right: 0.5rem;
  /* 8px */
}
.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 96vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
  /* Prevent content overflow */
}
#profileImg {
  border: none;
  cursor: pointer;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}

h1 {
  color: #06152b;
  font-weight: 700;
  font-size: 36px;
}

h2 {
  color: #06152b;
  font-weight: 700;
  font-size: 20px;
}

.firstContainer {
  display: flex;
  justify-content: space-between;
}

.firstContainer > div {
  display: flex;
  gap: 20px;
}

.firstContainer > div > span {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  border: 2px solid var(--primary-color);
}

.firstContainer > div > span:nth-child(3) > img {
  width: 100%;
  border-radius: 18px;
}

.firstContainer > div > span:nth-child(2) > img {
  width: 100%;
  border-radius: 18px;
}

.firstContainer > div > span:nth-child(2) {
  border: none !important;
}

.firstContainer > div > select:nth-child(1) {
  height: 60px;
  background-color: #f1f4f9;
  width: 11vw;
  -webkit-padding-start: 25px;
  border-radius: 18px;
  font-weight: 700;
  border: 2px solid var(--primary-color);
  padding-right: 40px;
  /* Adjust the padding value as per your requirement */
  background-image: url("../../assets/selectArrow.svg");
  /* Replace 'path/to/arrow-image.png' with the path to your arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  -webkit-appearance: none;
  /* Remove default arrow appearance on WebKit browsers */
  -moz-appearance: none;
  /* Remove default arrow appearance on Mozilla Firefox */
  appearance: none;
}

.logoutArrow {
  display: flex;
  justify-content: left;
  align-items: bottom;
  padding-top: 42px;
  margin-left: -18px;
}

.noSubscriptions p {
  font-weight: 500;
  text-align: center;
}

.noSubscriptions {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  border: 1px solid var(--primary-color);
  border-radius: 13px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card > h1 {
  font-size: 28px;
}

.card > p {
  color: #809fb8;
  font-weight: 700;
}

.card > img {
  height: 38px;
  width: 38px;
}

.chartDiv {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.chart {
  border: 1px solid var(--primary-color);
  border-radius: 13px;
  padding: 20px;
  flex: 5;
}

.sideTable {
  display: flex;
  flex: 2;
  border-radius: 13px;
  border: 1px solid var(--primary-color);
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
}

.sidechart {
  width: 100%;
}

.chartHead {
  margin-bottom: 10px;
}

.innerChartHead {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.innerChartHead > select {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  padding: 5px 30px 5px 10px;
  background-image: url("../../assets/selectArrow.svg");
  /* Replace 'path/to/arrow-image.png' with the path to your arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  -webkit-appearance: none;
  /* Remove default arrow appearance on WebKit browsers */
  -moz-appearance: none;
  /* Remove default arrow appearance on Mozilla Firefox */
  appearance: none;
}

.previous {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.thisYear {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profileDetails {
  display: flex;
  gap: 10px;
  padding-right: 40px;
}

.profilePic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.logout {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: red;
}

.title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.chartContent {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .main {
    max-height: calc(100vh - 165px);
    overflow-y: auto;
    padding-right: 10px;
  }

  .cards {
    flex-direction: column;
  }

  .chartDiv {
    display: block;
  }

  .cards .maincards {
    width: 100%;
  }

  .cards .mainpiediv {
    width: 100%;
  }

  .card {
    width: 100%;
  }

  .cards .piechartdiv {
    width: 300px;
  }

  .noSubscriptions {
    width: 100%;
  }

  .sideTable {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .MainContainer {
    padding: 10px;
    border-radius: 4px;
  }

  .chartDiv {
    flex-direction: column;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .mainpiediv {
    flex-direction: column;
  }

  .cards .maincards {
    width: 100%;
    flex-direction: column;
  }

  .firstContainer h1 {
    font-size: 16px;
    margin-left: 15px;
  }

  .chartHead {
    flex-direction: column;
  }

  .chartHead h2 {
    font-size: 16px;
  }

  .innerChartHead {
    font-size: 13px;
  }

  .chart {
    padding: 10px;
  }

  .tableDiv tr > th {
    font-size: 15px;
    padding: 10px;
  }

  .main {
    max-height: calc(100vh - 120px);
  }
}

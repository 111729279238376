.MainContainer {
  background-color: #fff;
  border-radius: 35px;
  width: 100%;
  padding: 30px;
  gap: 0px !important;
  min-height: calc(100vh - 20px);
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.MainContainer::-webkit-scrollbar {
  display: none;
}

h1 {
  white-space: nowrap;
}

.container1 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-left: 30px;
}

.container1 span {
  color: gray;
  font-size: 20px;
}

.container1 select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
}

.resetPassword p {
  opacity: 50%;
}

.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
}

.resetPassword select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.btn button {
  height: 60px;
  border-radius: 16px;
}
.breadcrumb {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .container {
    display: block;
  }
  h1 {
    font-size: 25px;
  }
  .container1 {
    padding-left: 0px;
  }
  .header h1 {
    font-size: 28px !important;
  }
}

.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 10px;
}

.topDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chatDiv {
  min-height: calc(100vh - 420px);
}

.sendMsgDiv {
  /* height: 70px; */
  background-color: #f5fafd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  padding: 5px;
  position: relative;
}

.previewDiv {
  position: absolute;
  height: 100px !important;
  width: 100px !important;
  bottom: 70px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  border-radius: 5px;
}

.prevCross {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid var(--primary-color);
  padding: 5px;
  font-size: 25px;
  border-radius: 50%;
  background-color: #fff;
  color: red;
  cursor: pointer;
}

.sendMsgDiv textarea {
  background-color: #f5fafd;
  width: 90%;
  height: 100%;
  outline: none;
  resize: none;
  border-radius: 13px;
}

.sendMsgDiv div {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
}

.sendMsgDiv div img {
  width: 50%;
  height: 50%;
}

.selectDiv {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 5px;
  white-space: nowrap;
  margin: 10px 0;
}

.selectDiv select {
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 10px 10px 25px;
}

.subjectDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subjectDiv input {
  outline: none;
  font-size: 24px;
  font-weight: bold;
}

.subjectDiv input::placeholder {
  outline: none;
  font-size: 24px;
  color: #c5c8cd;
  font-weight: 500;
}

.selectPartner {
  border: none;
  padding: none;
}

.autoComplete {
  width: 400px;
}

@media only screen and (max-width: 767px) {
  .autoComplete {
    width: 200px;
  }
  .headTitle {
    font-size: 20px !important;
    padding: 10px 20px;
  }
  .chatDiv {
    min-height: calc(100vh - 375px);
  }
}

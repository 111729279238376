.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 10px;
}
.topDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chatDiv {
  min-height: 50vh;
}
.sendMsgDiv {
  /* height: 70px; */
  background-color: #f5fafd;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 13px;
  padding: 10px;
}
.sendMsgDiv textarea {
  background-color: #f5fafd;
  width: 90%;
  height: 100%;
  outline: none;
  resize: none;
  border-radius: 13px;
}
.sendMsgDiv div {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
}
.sendMsgDiv div img {
  width: 50%;
  height: 50%;
}
.selectDiv {
  display: flex;
  padding-bottom: 5px;
  white-space: nowrap;
  margin: 10px 0;
}

.subjectDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subjectDiv input {
  outline: none;
  font-size: 24px;
  font-weight: bold;
}
.subjectDiv input::placeholder {
  outline: none;
  font-size: 24px;
  color: #c5c8cd;
  font-weight: 500;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 35px;
  padding: 30px;
  gap: 30px;
  overflow: hidden;
  /* Prevent content overflow */
}

.MainContainer::-webkit-scrollbar {
  display: none;
}

.firstDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterDiv {
  display: flex;
  gap: 10px;
}

.tableDiv {
  overflow: scroll;
  width: 100%;
  font-size: 13px;
  text-align: center !important;
}

.tableDiv::-webkit-scrollbar {
  display: none;
}

.tableDiv table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding-left: 80px;
  text-align: center;
}

.tableDiv tr > th {
  color: #8f8f8f;
  text-align: center;
  white-space: nowrap;
  padding: 15px 40px 15px 40px;
}

.tableDiv tr:nth-child(1) > th:nth-child(1) {
  /* border-left: 1px solid var(--primary-color);
  border-radius: 13px; */
}

.tableDiv tr:nth-child(1) > th:last-child {
  /* border-right: 1px solid var(--primary-color); */
}

.tableDiv tr > td {
  text-align: center;
}

.tableDiv td {
  /* border-bottom: 1px solid var(--primary-color); */
  text-align: left;
  padding: 8px;
  text-align: center;
  margin-bottom: 10px;
  /* Increase the gap between table elements */
}

.actionDiv {
  display: flex;
  gap: 10px;
}

.actionDiv2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blackBtn {
  color: #fff;
  background-color: #333333;
  padding: 10px;
  border-radius: 22px;
}

.blackBtn2 {
  color: #fff;
  background-color: #333333;
  padding: 10px 0;
  border-radius: 22px;
  white-space: nowrap;
}

.blackBtn3 {
  color: #ff5d4d;
  background-color: #fbebee;
  padding: 10px 0;
  border-radius: 22px;
  white-space: nowrap;
}

.blackBtn26 {
  color: #333333;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid black;
}

.blackBtn36 {
  color: #ff5d4d;
  border: 1px solid #ff5d4d;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 13px;
}

.table th {
  padding: 8px;
  text-align: center;
  /* border-top: 1px solid var(--primary-color); */
  z-index: 10;

  position: sticky;
  top: -1px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.table td {
  padding: 8px;
  text-align: center;
}

.table td:first-child {
  /* border-left: 1px solid var(--primary-color);
  border-radius: 10px; */
}

.table td:last-child {
  /* border-right: 1px solid var(--primary-color); */
}

.table tr:first-child td {
  /* border-top: 1px solid var(--primary-color); */
}

.table tr:last-child td {
  /* border-bottom: 1px solid var(--primary-color); */
}

.numberSpan {
  padding: 2px 15px;
  background-color: var(--primary-color);
  border-radius: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  z-index: -1;
}

.profilePic {
  display: flex;
  align-items: center;
  gap: 10px;
}

.spin {
  position: absolute;
  left: 55vw;
  top: 60vh;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.deleteModeldiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  text-align: center;
}

.deleteModeldiv p {
  font-weight: 700;
  font-size: 20px;
}

.deleteModeldiv img {
  width: 40%;
  border-radius: 13px;
}

.deleteModeldiv > textarea {
  width: 80%;
  outline: none;
  background-color: #f4f9fc;
  padding: 10px;
  resize: none;
  border-radius: 10px;
}

.modelFooter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modelFooter button:nth-child(1) {
  background-color: black;
  color: #fff;
  width: 100px;
  border-radius: 20px;
}

.modelFooter button:nth-child(2) {
  border: 1px solid black;
  width: 100px;
  border-radius: 20px;
}

.warningText {
  color: red;
  font-weight: 500 !important;
}

.main {
  max-height: calc(100vh - 165px);
  min-height: calc(100vh - 165px);
  overflow-y: auto;
  padding-right: 10px;
}

.tabPanel {
  color: black !important;
  font-weight: 600;
  white-space: nowrap;
}

@media only screen and (max-width: 1024px) {
  .MainContainer {
    padding: 20px;
  }

  .tabList {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 2px;
  }

  .tabPanel {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .MainContainer {
    padding: 10px;
    border-radius: 4px;
  }

  .firstDiv {
    flex-direction: column;
    align-items: start;
    gap: 4px;
    width: 100%;
  }

  .filterDiv {
    width: 100%;
  }

  .searchBar {
    max-width: 100% !important;
  }

  .firstDiv h1 {
    margin-top: 8px;
    font-size: 24px !important;
    margin-left: 15px;
  }
}

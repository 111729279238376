.MainContainer {
  background-color: #fff;
  border-radius: 35px;
  width: 100%;
  padding: 30px;
  gap: 0px !important;
  min-height: calc(100vh - 20px);
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.MainContainer::-webkit-scrollbar {
  display: none;
}

h1 {
  white-space: nowrap;
}

.container1 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
}

.rightSide {
  display: flex;
  margin-top: 10%;
  align-items: center;
  justify-content: center;
}

.suggestionImage {
  width: 100%;
}

.container2 {
  position: relative;
  padding: 20px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto;
}

.imageContainer1 {
  position: relative;
  width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adPreviewTitle p {
  font-size: 18px !important;
  color: white !important;
  margin: 10px 0px;
}

.previewAdBody {
  font-size: 14px;
  color: #b9b9b9;
  margin: 10px 0px;
  max-width: 250px;
}

.previewAdButton button {
  background: white;
  padding: 5px 10px;
  width: 150px;
  border-radius: 30px;
  color: #000;
  text-align: center;
}

.previewAdButton button span {
  color: #000 !important;
}

.imageInside {
  position: absolute;
  left: 34px;
  bottom: 71px;
  width: 305px;
  overflow: hidden;
  max-height: 530px;
  overflow-y: auto;
}

.imageInside1 {
  width: 310px;
  height: auto;
  object-fit: cover;
  position: absolute;
  left: 37px;
  height: 310px;
  top: calc(50% - 100px);
}

.previewText {
  width: 100%;
}

.previewText p:nth-child(1) {
  font-size: 1.3vw;
  font-weight: bolder;
}

.previewText p:nth-child(2) {
  font-size: 1.3vw;
  font-weight: bolder;
}

.previewText p:nth-child(3) {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #c2c2c2;
  margin-top: 1.5%;
}

.previewInputImage {
  display: flex;
  justify-content: center;
}

.previewInputImage1 {
  /* width: 94%; */
  display: flex;
  justify-content: center;
}

.previewInputImage1 img {
  display: flex;
  /* width: 96%; */
  object-fit: contain;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}

.paragraph {
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 3%;
}

.paragraph1 {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #b9b9b9;
  margin-top: 3%;
}

.buttons1 {
  border: 1px solid #000;
  padding: 8px 16px;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  width: 46%;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
}

.buttons2 {
  border: 1px solid #000;
  padding: 8px 16px;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  width: 46%;
  background-color: #333333;
  color: #ffffff;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
}

.div2 {
  height: 100px;
  padding: 10px;
}

.div3 {
  display: flex;
  margin-bottom: 10px;
}

.div1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  object-fit: fill;
  background-color: #ffffff;
  border-radius: 22px;
}

.previewInputImage img {
  display: flex;
  object-fit: contain;
  margin-left: 2%;
  margin-top: 2%;
}

.previewAdDetails {
  width: 100%;
  display: flex;
  margin-top: 3%;
  align-items: center;
}

.adPreviewTitle {
  width: 80%;
}

.adPreviewTitle p {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.locations {
  display: flex;
  gap: 7px;
  align-items: center;
}

.locations img {
  height: 2vh;
}
.breadcrumb {
  display: flex;
}
.locations p {
  font-size: 0.8rem;
}

.stars {
  margin-top: 2%;
  margin-left: 2%;
}

.container1 span {
  color: gray;
  font-size: 20px;
}

.container1 select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: 1px solid var(--primary-color);
  padding: 20px;
}

.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
}

.resetPassword textarea {
  background-color: #ecf1f4;
  border-radius: 16px;
  outline: none;
  padding: 10px;
  resize: none;
}

.resetPassword select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.resetPassword > p {
  color: gray;
}

.datepicker {
  width: 100%;
}

.date_picker,
.date_picker > div.react-datepicker-wrapper,
.date_picker
  > div
  > div.react-datepicker__input-container
  .date_picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  padding: 0 10px;
}

.btn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.btn button {
  height: 60px;
  border-radius: 16px;
}

.radioP {
  color: #333333;
  font-weight: 400;
  font-size: 15px;
}

.longString {
  font-size: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

@media only screen and (max-width: 1024px) {
  .MainContainer {
    padding: 10px;
    border-radius: 10px;
  }

  .grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .resetPassword {
    padding: 30px 0px;
    border-right: none;
    border-bottom: 1px solid var(--primary-color);
  }

  h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 747px) {
  .header {
    flex-wrap: wrap;
  }
  .imageContainer1 {
    max-width: 350px;
  }
}

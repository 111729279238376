.formDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: #fff;
  padding: 10% 15% 10% 15%;
  border-radius: 13px;
}
.formDiv > h2 {
  color: var(--primary-btn-color);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}
.formDiv > Button {
  background-color: var(--primary-btn-color);
  color: #ffffff;
  height: 60px;
  border-radius: 30px;
  font-size: 20px;
  width: 80%;
  margin: 40px auto 0 auto;
}
.gif {
  width: 25%;
  margin: auto;
}


@media only screen and (max-width: 767px) {
  .formDiv {
    margin-top: 10px;
    padding: 20px;
  }
  .formDiv h2 {
   font-size: 20px;
   margin-bottom: 10px;
  }
}
.MainContainer {
  background-color: #fff;
  border-radius: 35px;
  width: 100%;
  padding: 30px;
  gap: 0px !important;
  min-height: calc(100vh - 20px);
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}
.cityMain {
  margin-top: 20px;
}
.cityMain p {
  margin: 15px 0px;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

h1 {
  white-space: nowrap;
}

.container1 {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.container2 {
  padding: 10px;
  border-left: 1px solid var(--primary-color);
  padding-left: 30px;
}

.container1 span {
  color: gray;
  font-size: 20px;
}

.datepicker {
  width: 100%;
}

.date_picker,
.date_picker > div.react-datepicker-wrapper,
.date_picker
  > div
  > div.react-datepicker__input-container
  .date_picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  padding: 0 10px;
}

.container1 select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-width: 450px;
}

.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
}

.resetPassword select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.btn button {
  height: 60px;
  border-radius: 16px;
}

.dateDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.preview {
  margin-top: 20px;
}

.previewInner {
  border: 1px solid var(--primary-color);
  border-radius: 35px;
  margin-top: 20px;
  padding: 15px;
  display: flex;
  gap: 10px;
  word-break: break-all;
}

.spanIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 20px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.citydiv {
  display: flex;
  padding-left: 10px;
  min-height: 60px;
  padding: 10px;
  gap: 5px;
  border-radius: 16px;
  background-color: #ecf1f4;
  align-items: center;
}

.citydiv input {
  height: 40px;
  border-radius: 16px;
  outline: none;
  width: 5vw;
}

.selectedCity {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.scrollstack {
  display: flex;
  align-items: center;
  max-height: 150px;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.breadcrumb {
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .MainContainer {
    padding: 10px;
    border-radius: 10px;
  }

  .grid {
    display: block;
  }

  .resetPassword {
    padding: 30px 0px;
    max-width: 100%;
  }

  .container2 {
    padding-left: 10px;
    border-left: none;
    border-top: 1px solid var(--primary-color);
  }
  .breadcrumb {
    margin-top: 8px;
    margin-left: 15px;
  }
  .header h1 {
    margin-top: 8px;
    margin-left: 15px;
    font-size: 24px !important;
  }
}

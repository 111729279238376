.cancelButton {
    color: #fff;
    background-color: #333333;
    padding: 5px 30px 5px 30px;
    border-radius: 22px;
    white-space: nowrap;
    cursor: pointer;
}

.crossImage {
    display: flex;
    justify-content: center;
    margin-top: 4%;
}

.crossImage img {
    width: 23%;
}

.cancelContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
}



.actionButton {
    display: flex;
    justify-content: center;
    gap: 6%;
}

.actionButton button {
    padding: 3% 8%;
    font-size: 0.8rem;
    font-weight: 400;
    background-color: #333333;
    color: white;
    border-radius: 22px;
    margin-top: 6%;
    margin-bottom: 5%;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
}

.actionButton button:last-child {
    border: 1px solid #333333;
    background-color: white;
    color: #333333;
}

.container1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.rightSide {
    display: flex;
    margin-top: 10%;
    align-items: center;
    justify-content: center;
}

.suggestionImage {
    width: 100%;
}

.container2 {
    position: relative;
    padding: 20px;
    padding-right: 0px;
}

.imageContainer {
    position: relative;
    overflow: hidden;
    max-width: 400px;
    margin: 0 auto;
}

.imageContainer1 {
    position: relative;
    width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}




.imageInside {
    position: absolute;
    left: 34px;
    bottom: 71px;
    width: 305px;
    overflow: hidden;
    max-height: 530px;
    overflow-y: auto;

}

.imageInside1 {
    width: 310px;
    height: auto;
    object-fit: cover;
    position: absolute;
    left: 37px;
    height: 310px;
    top: calc(50% - 100px);

}

.previewText {
    width: 100%;
}

.previewText p:nth-child(1) {
    font-size: 1.3vw;
    font-weight: bolder;
}

.previewText p:nth-child(2) {
    font-size: 1.3vw;
    font-weight: bolder;
}

.previewText p:nth-child(3) {
    font-family: Helvetica;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #c2c2c2;
    margin-top: 1.5%;
}

.previewInputImage {
    display: flex;
    justify-content: center;
}


.previewInputImage1 {
    /* width: 94%; */
    display: flex;
    justify-content: center;
}

.previewInputImage1 img {
    display: flex;
    /* width: 96%; */
    object-fit: contain;
    width: 100%;
    ;
}

.paragraph {
    font-family: Helvetica;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.paragraph1 {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #B9B9B9;
    margin-top: 5px;
}

.buttons1 {
    border: 1px solid #000;
    padding: 8px 16px;
    display: block;
    margin: 0 auto;
    border-radius: 20px;
    width: 46%;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 40px;
}

.buttons2 {
    border: 1px solid #000;
    padding: 8px 16px;
    display: block;
    margin: 0 auto;
    border-radius: 20px;
    width: 46%;
    background-color: #333333;
    color: #FFFFFF;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 40px;
}

.div2 {
    height: 100px;
    padding: 10px;
}

.div3 {
    display: flex;
    margin-bottom: 10px;
}

.div1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    object-fit: fill;
    background-color: #FFFFFF;
    border-radius: 22px;
    padding: 5px;
}

.previewInputImage img {
    display: flex;
    object-fit: contain;
}

.imgBox {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 22px;
}

.previewAdDetails {
    width: 100%;
    display: flex;
    margin-top: 3%;
    align-items: center;
}

.adPreviewTitle {
    width: 80%;
}

.adPreviewTitle p {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
}

.locations {
    display: flex;
    gap: 7px;
    align-items: center;
}

.locations img {
    height: 2vh;
}

.locations p {
    font-size: 0.8rem;
}

.stars {
    margin-top: 2%;
    margin-left: 2%;
}

.container1 span {
    color: gray;
    font-size: 20px;
}

.container1 select {
    background-color: #ecf1f4;
    height: 60px;
    border-radius: 16px;
    display: flex;
    align-items: center;
}
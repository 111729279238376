.cancelButton {
  background-color: #ffefed !important;
  color: #db3b53 !important;
  border-radius: 20px !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  width: 130px;
  padding: 0px;
}
.cancelDisabledButton {
  background-color: gray !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  width: 130px;
  padding: 0px;
}
.blackBtn3 {
  color: #ff5d4d;
  border: 1px solid #ff5d4d;
  padding: 10px !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
}
.deleteButton {
  background-color: #ffefed !important;
  color: #db3b53 !important;
  border-radius: 20px !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  width: 40px;
  padding: 0px;
}

.crossImage {
  display: flex;
  justify-content: center;
  margin-top: 4%;
}

.crossImage img {
  width: 23%;
}

.cancelContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

.cancelContent p {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.8rem;
}

.actionButton {
  display: flex;
  justify-content: center;
  gap: 6%;
}

.actionButton button {
  padding: 3% 8%;
  font-size: 0.8rem;
  font-weight: 400;
  background-color: #333333;
  color: white;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 5%;
}

.actionButton button:last-child {
  border: 1px solid #333333;
  background-color: white;
  color: #333333;
}

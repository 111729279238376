.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.firstDiv {
  display: flex;
  justify-content: space-between;
}
.firstDiv button {
  height: 60px;
  border-radius: 15px;
}
.head {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.datepicker {
  width: 100%;
}

.date_picker,
.date_picker > div.react-datepicker-wrapper,
.date_picker
  > div
  > div.react-datepicker__input-container
  .date_picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  padding: 0 10px;
}

.secondDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.btn {
  display: flex;
  gap: 10px;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 150px;
}
.title {
  color: #333333;
  opacity: 50%;
}
.name {
  display: flex;
  justify-content: space-between;
  background-color: #ecf1f4;
  height: 60px;
  align-items: center;
  border-radius: 16px;
}
.name input {
  border: none;

  outline: none;
}
.name input:focus {
  outline: none;
}
.name input::placeholder {
  color: black;
}
.photoDiv textarea {
  background-color: #ecf1f4;
  height: 200px;
  border-radius: 16px;
  resize: none;
  padding: 10px;
  outline: none;
}
.photoDiv textarea::-webkit-scrollbar {
  display: none;
}
.verified {
  background-color: #15eeb0;
  padding: 5px 10px;
  border: none;
}
.interestDiv {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  background-color: #f4f9fc;
  padding: 10px;
  border-radius: 13px;
}
.interestDiv button {
  display: flex;
  background-color: #8cc9e9;
  border-radius: 15px;
  padding: 4px 16px 6px;
}
.photoDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 100px;
}
.photos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.uploadMain {
  border: 1px dashed gray;
  padding: 90px;
  border-radius: 16px;
  cursor: pointer;
}
.uploadSub {
  border: 1px dashed gray;
  padding: 36px;
  border-radius: 16px;
  cursor: pointer;
}
.subDiv1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subDiv2 {
  display: flex;
  gap: 10px;
}
.mainPhoto {
  width: 200px;
  height: 200px;
  position: relative;
}
.mainPhoto > img {
  border-radius: 16px;
  height: 100%;
  object-fit: cover;
}

.subPhoto {
  width: 100px;
  height: 100px;
  position: relative;
}
.subPhoto > img {
  border-radius: 16px;

  height: 100%;
  object-fit: cover;
}
.mainCross {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.MainContainer {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 95vh;
    border-radius: 35px;
    width: 98%;
    padding: 3%;
  }
  .MainContainer::-webkit-scrollbar {
    display: none;
  }
  .firstSpan {
    display: flex;
    gap: 5px;
    font-family: Helvetica;
  }

  .firstSpan p:first-child{
    font-family: Inter;
    font-weight: 700;
    font-size: 0.95rem;
    color: #333333;
    opacity: 50%;
    cursor: pointer;
  }

  .firstSpan p:last-child{
    font-size: 0.95rem;
  }
  
  .PartnerHeader{
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 2%;
  }

  .Partner{
    display: flex;
    width: 81.5%;
    gap: 2%;
    align-items: center;
  }

  .Partner p:first-child{
    font-size: 1.1rem;
    font-family: Helvetica;
    font-weight: 700;
  }

  .Partner p:last-child{
    font-size: 0.8rem;
    font-family: Helvetica;
    font-weight: 400;
    background-color: #fff7ef;
    color:#FFB060;
    padding: 0.2% 1.5%;
    border-radius: 8px;

  }

  .PartnerHeader button {
    padding: 1% 3%;
    background-color: #333333;
    color: #FEFEFE;
    border-radius: 10px;
    font-size: 0.8rem;
    font-family: Helvetica;
    margin-left: 11%;
  }

  .buttonAction{
    display: flex;
    gap: 18%;
  }

  .buttonAction button:first-child{
    padding: 12% 25%;
    border: 1px solid #333333;
    background-color: #FEFEFE;
    color: #333333;
    font-weight: 400;
    border-radius: 10px;
    font-size: 0.8rem;
    font-family: Helvetica;
  }

  .buttonAction button:last-child{
    padding: 12% 35%;
    border: none;
    background-color: #333333;
    color: #FEFEFE;
    font-weight: 400;
    border-radius: 10px;
    font-size: 0.8rem;
    font-family: Helvetica;
  }

  .partnerDetails{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1%;
  }

  .partnerContactDetails{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
  }

  .partnerAddress{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2%;
  }

  .partnerAddress img {
    width: 1rem;
    height: 1rem;
  }

  .partnerAddress p {
    font-size: 0.8rem;
    color: #d8d8d8;
    font-family: Helvetica;
    font-weight: 400;
  }

  .partnerStatusDetails{
    width: 100%;
    display: flex;
    gap: 5%;
    border-top: 2px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 2% 0;
  }

  .singleDetails{
    width: 15%;
  }

  .singleDetails p:first-child{
    color: #d8d8d8;
    font-family: Helvetica;
    font-size: 0.8rem;
    font-family: 400;
  }

  .singleDetails p:last-child{
    color: #000000;
    font-family: Helvetica;
    font-size: 0.8rem;
    font-family: 400;
  }

  .partnerPics{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .partnerPics p{
    margin-top: 1%;
    color: #d8d8d8;
    font-size: 0.8rem;
  }

  .picsContainer{
    display: flex;
    gap: 2.5%;
    width: 100%;
  }

  .singlePic{
    width: 15%;
    height: 18vh;
    border-radius: 10px;
    position: relative;
  }

  .singlePic img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    object-fit: cover;
  }

  .removeImage{
    position: absolute;
    top: 2%;
    right: 1%;
    width: 12%;
    cursor: pointer;
  }

  .removeImage img {
    width: 100%;
  }


  .editParterDetails{
    display: flex;
    gap: 6%;
    margin-top: 0%;
    box-sizing: border-box;
  }

  .editSingleDetails{
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  .editSingleDetails p{
    font-size: 0.8rem;
    font-family: Helvetica;
    font-weight: 400;
    color: #d8d8d8;
  }

.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--primary-color);
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
}

h1 {
  font-size: 32px !important;
}

.container>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container>div:nth-child(1) {
  flex-direction: column;
  text-align: center;
  font-size: 32px;
  gap: 20px;
  font-weight: 700;
}

.container>div:nth-child(1)>img {
  width: 20%;
}

.formDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: #fff;
  border-radius: 13px;
  padding: 10% 15%;
}

.formDiv>h2 {
  color: var(--primary-btn-color);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 60px;
}

.formDiv>Button {
  background-color: var(--primary-btn-color);
  color: #ffffff;
  margin-top: 30px;
  height: 50px;
  border-radius: 30px;
  font-size: 20px;
}

.formDiv input {
  font-size: 18px;
  border-radius: 13px;

  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.formDiv input::placeholder {
  font-size: 18px;
  color: #7a7a7a;
}

.inputText {
  color: #343434;
  font-size: 16px;
}

.forgetLink {
  text-align: right;
  color: var(--primary-btn-color);
  font-size: 18px;
  font-weight: 600;
}




@media only screen and (max-width: 768px) {

  .container {
    display: block;

  }

  .formDiv {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .formDiv {
    margin-top: 10px;
    padding: 20px;
  }

  .formDiv h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .container {
    display: block;
  }
}
.MainContainer {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 95vh;
    border-radius: 35px;
    width: 98%;
    padding: 3%;
    gap: 10px;
  }
  .MainContainer::-webkit-scrollbar {
    display: none;
  }
  .profileDiv{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
  .tableDiv {
    overflow: scroll;
    height: 74vh;
    width: 73vw;
    font-size: 13px;
    margin-top: 10px;
    text-align: center !important;
  }
  .tableDiv::-webkit-scrollbar {
    display: none;
  }
  .tableDiv table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    padding-left: 80px;
    text-align: center;
  }
  
  .tableDiv tr > th {
    color: #8f8f8f;
    text-align: center;
    white-space: nowrap;
    z-index: 10;
    padding: 5px 50px 5px 50px;
  }
  .tableDiv tr:nth-child(1) > th:nth-child(1) {
    border-left: 1px solid var(--primary-color);
    border-radius: 13px;
  }
  .tableDiv tr:nth-child(1) > th:last-child {
    border-right: 1px solid var(--primary-color);
  }
  
  .tableDiv tr > td {
    text-align: center;
  }
  
  .tableDiv td {
    border-bottom: 1px solid var(--primary-color);
    text-align: left;
    padding: 8px;
    text-align: center;
    margin-bottom: 10px; /* Increase the gap between table elements */
  }
  .table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 13px;
  }
  
  .table th {
    padding: 8px;
    text-align: center;
    border-top: 1px solid var(--primary-color);
    position: sticky;
    top: -1px;
    background-color: #fff;
    padding-bottom: 5px;  
  }
  .check{
    display: flex;
    justify-content: center;
  }
  .table td {
    padding: 8px;
    text-align: center;
  }
  
  .table td:first-child {
    border-left: 1px solid var(--primary-color);
    border-radius: 10px;
  }
  
  .table td:last-child {
    border-right: 1px solid var(--primary-color);
  }
  
  .table tr:first-child td {
    border-top: 1px solid var(--primary-color);
  }
  
  .table tr:last-child td {
    border-bottom: 1px solid var(--primary-color);
  }
  .profileDetails {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 20px;
    z-index: -1;
  }
  .profileDetails2 {
    display: flex;
    flex-direction: column;
    text-align: left;
    z-index: -1;
  }
  .profilePic {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }
  .reason{
    height: 40px;
    overflow: scroll;
  
  }
  .reason::-webkit-scrollbar{
    display: none;
  }
  .iconSpan{
    display: flex;
    align-items: center;
    gap: 10px;
  }
.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.tableDiv {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  height: 65vh;
  overflow: scroll;
}
.tableDiv::-webkit-scrollbar {
  display: none;
}
.tableHead {
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 60px;
  align-items: center;
  padding: 20px 50% 20px 20px;
  color: #8f8f8f;
}
.tableDiv div {
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 60px;
  border-bottom: 1px solid var(--primary-color);
  align-items: center;
  padding: 20px 50% 20px 20px;
}
.period {
  margin-left: 10%;
}
.tableDiv div {
}

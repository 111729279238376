.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 35px;
  padding: 30px;
  gap: 30px;
  overflow: hidden;
  min-height: calc(100vh - 20px);
  /* Prevent content overflow */
}
.actionDivMain {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-items: center;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}

.firstDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstDiv input::placeholder {
  font-size: 0.9rem;
  font-weight: 700;
  color: #333333;
}

.heading > .numberSpan {
  margin-left: 10px;
  padding: 2px 5px;
  margin-top: 10px;
}

.tableDiv {
  overflow: scroll;
  width: 100%;
  font-size: 13px;
  text-align: center !important;
}

.tableDiv::-webkit-scrollbar {
  display: none;
}

.tableDiv table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  text-align: center;
}

.tableDiv tr > th {
  color: #8f8f8f;
  text-align: center;
  white-space: nowrap;
  /* padding: 0 40px 15px 40px; */
  font-size: 14px;
  padding: 15px 0px;
}

.tableDiv tbody > tr {
  border-bottom: 1px solid #ddd;
}

.tableDiv tbody > tr:last-child {
  border-bottom: none;
}

.tableDiv tr > td {
  text-align: center;
  font-size: 14px;
}

.tableDiv td {
  /* border-bottom: 1px solid var(--primary-color); */
  text-align: left;
  padding: 8px;
  text-align: center;
  /* Increase the gap between table elements */
}

.actionDiv {
  display: flex;
  gap: 10px;
}

.actionDiv2 {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.blackBtn {
  color: #fff;
  background-color: #333333;
  padding: 10px;
  border-radius: 22px;
  white-space: nowrap;
  cursor: pointer;
}

.blackBtn2 {
  color: #fff;
  background-color: #333333;
  padding: 10px 25px 10px 25px;
  border-radius: 22px;
  white-space: nowrap;
  cursor: pointer;
}

.blackBtn10 {
  color: #fff;
  background-color: #333333;
  border-radius: 22px;
  white-space: nowrap;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blackBtn29 {
  color: #fff;
  background-color: #333333;
  border-radius: 22px;
  width: 80px;
  margin: auto;
  padding: 10px;
  cursor: pointer;
}

.blackBtn3 {
  color: #ff5d4d;
  background-color: #fbebee;
  padding: 10px 25px 10px 25px;
  border-radius: 22px;
  white-space: nowrap;
  cursor: pointer;
}

.blackBtn4 {
  color: #333333;
  background-color: var(--primary-color);
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
  cursor: pointer;
}

.table {
  /* border-collapse: collapse; */
  width: 100%;
}

.table th {
  padding: 15px 8px;
  text-align: center;
  /* border-bottom: 1px solid var(--primary-color); */
  position: sticky;
  font-weight: lighter !important;
  top: -1px;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 14px;
  white-space: nowrap;
}

.table td {
  padding: 8px;
  text-align: center;
  font-size: 14px;
  border-top: 1px solid var(--chakra-colors-chakra-border-color);
}

.numberSpan {
  padding: 2px 15px;
  background-color: var(--primary-color);
  border-radius: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
}

.spin {
  position: absolute;
  left: 55vw;
  top: 60vh;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.tabPanel {
  color: black !important;
  font-weight: 600;
  white-space: nowrap;
}

.tabPanel:active {
  color: black !important;
}

#activeTab {
  background-color: #8cc9e9;
}

.address {
  white-space: pre-wrap;
  text-align: left;
}

.address::-webkit-scrollbar {
  display: none;
}

.tableContainer {
  max-height: calc(100vh - 300px);
  overflow-y: auto !important;
  border: 1px solid;
  border-color: var(--chakra-colors-gray-100);
}

/* width */
.tableContainer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.tableContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.tableContainer::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table thead {
  position: relative;
  z-index: 1;
}

.actionDiv {
  flex-wrap: wrap;
  justify-content: center;
}

.tabPanels {
  padding: 10px 0px !important;
}

@media only screen and (max-width: 1024px) {
  .MainContainer {
    padding: 20px;
  }

  .tabList {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 2px;
  }

  .tabPanel {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .MainContainer {
    padding: 10px;
    border-radius: 4px;
  }

  .firstDiv {
    flex-direction: column;
    align-items: start;
    gap: 4px;
    width: 100%;
  }

  .filterDiv {
    width: 100%;
  }

  .searchBar {
    max-width: 100% !important;
  }

  .firstDiv h1 {
    font-size: 18px;
  }
}

/* .main {
  max-height: calc(100vh - 165px);
  overflow-y: auto;
  padding-right: 10px;
} */

@media only screen and (max-width: 1024px) {
  .MainContainer {
    padding: 20px;
  }

  .tabPanel {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .MainContainer {
    padding: 10px;
    border-radius: 4px;
  }

  .firstDiv {
    flex-direction: column;
    align-items: start;
    gap: 4px;
    width: 100%;
  }

  .filterDiv {
    width: 100%;
  }

  .searchBar {
    max-width: 100% !important;
  }

  .firstDiv h1 {
    margin-top: 8px;
    font-size: 24px !important;
    margin-left: 15px;
  }
}

.ant-select-selection-search {
  cursor: pointer !important;
}

.ant-select-selection-search-input {
  cursor: pointer !important;
}

svg > g > g:last-child {
  pointer-events: none;
}

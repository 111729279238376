.modalContent {
  max-width: 40vw !important;
}
.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  /* width: 35vw; */
  padding: 3%;
  gap: 30px;
  font-family: Helvetica;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}

.breadCrumb span {
  font-size: 1rem;
}

.datepicker {
  width: 150px;
}

.date_picker,
.date_picker > div.react-datepicker-wrapper,
.date_picker
  > div
  > div.react-datepicker__input-container
  .date_picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 150px;
  padding: 0 10px;
}

.adDetails {
  display: flex;
  flex-direction: column;
}

.adDetails > p:first-child {
  color: #06152b;
  font-weight: 700;
  font-size: 1.4rem;
}
.adDetails > p:nth-child(2) {
  color: #333333;
  font-weight: 700;
  font-size: 1.3rem;
}

.adContent {
  margin-top: 1%;
}

.adContent p {
  color: #333333;
  opacity: 50%;
  font-size: 1rem;
  font-weight: 400;
}

.location {
  display: flex;
  align-items: center;
  gap: 5px;
}

.location > img {
  width: 13px;
  height: 16px;
}

.location p {
  size: 1rem;
  font-weight: 400;
  color: #333333;
}

.adInfo {
  display: flex;
  gap: 20%;
  margin-top: 2%;
}

.adType {
  display: flex;
  flex-direction: column;
}

.adType p:first-child {
  font-size: 0.8rem;
  color: #333333;
  opacity: 50%;
}

.adType p:last-child {
  font-size: 0.8rem;
  color: #333333;
}

.chart {
  margin-top: -1% !important;
  height: 48vh;
}

.filterDiv2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 0px;
}

.innerFilter {
  display: flex;
}

.filterMenu {
  width: 45%;
}
.filterItems {
  width: 55%;
  box-sizing: border-box;
  padding-left: 3%;
}

.filterItems > p {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.adTypeRadio {
  display: flex;
  gap: 10px;
  margin-top: 1%;
}

.adTypeRadio > div {
  width: 48%;
  padding: 2.5% 0;
  border: 1px solid #333333;
  border-radius: 10px;
  display: flex;
  gap: 10px;
}
.adTypeRadio > div > input[type="radio"] {
  margin-left: 6%;
}

.dateSelect {
  display: flex;
  gap: 20px;
}

.singlePicker {
  width: 49%;
}
.innerFilter input {
  border: 1px solid var(--primary-color);
  padding: 10px 15px;
  border-radius: 8px;
  outline: none;
}

.blackBtn {
  color: black;
  background-color: #fff;
  padding: 10px 30px;
  border: 1px solid #333333;
  border-radius: 22px;
}
.blackBtn2 {
  color: #fff;
  background-color: #333333;
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
}

.modalBody {
  -webkit-padding-start: 0 !important;
  padding-inline-start: 0 !important;
}

.activeCard {
  border-left-width: 8px !important;
  border-left-color: #8cc9e9;
  background-color: #f3fafd;
}

.inactive {
  background-color: #d9d9d9 !important;
  opacity: 40%;
}

@media only screen and (max-width: 1024px) {
  .dateSelect {
    flex-direction: column;
  }
  .modalContent {
    max-width: 90vw;
  }
  .adTypeRadio {
    flex-direction: column;
  }

  .adTypeRadio > div {
    width: 100%;
  }

  .singlePicker {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .modalContent {
    max-width: 90vw;
  }
  .innerFilter {
    flex-direction: column;
  }
  .filterMenu {
    width: 100%;
  }
  .filterItems {
    width: 100%;
  }
}

.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.firstDiv {
  display: flex;
  justify-content: space-between;
}
.firstDiv button{
  height: 60px;
  border-radius: 15px;
}
.head {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.secondDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.btn{
    display: flex;
    gap: 10px;
    
}
.details{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title{
  color: #333333;
  opacity: 50%;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 0.9rem;
}
.name{
  display: flex;
  justify-content: space-between;
  padding: 0 180px 0  0;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 0.9rem;
}
.verified{
  background-color: #15EEB0;
  padding: 2px 5px;
}
.interestDiv{
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.interestDiv button{
  display: flex;
  background-color: #8CC9E9;
  border-radius: 15px;
  padding: 4px 16px 6px;
}

.photoDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 100px;
}
.photos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.uploadMain {
  border: 1px dashed gray;
  padding: 90px;
  border-radius: 16px;
  cursor: pointer;
}
.uploadSub {
  border: 1px dashed gray;
  padding: 36px;
  border-radius: 16px;
  cursor: pointer;
}
.subDiv1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subDiv2 {
  display: flex;
  gap: 10px;
}
.mainPhoto {
  width: 200px;
  height: 200px;
  position: relative;
}
.mainPhoto > img {
  border-radius: 16px;
  height: 100%;
  object-fit: cover;
}

.subPhoto {
  width: 100px;
  height: 100px;
  position: relative;
}
.subPhoto > img {
  border-radius: 16px;

  height: 100%;
  object-fit: cover;
}
.mainCross {
  position: absolute;
  right: 0;
  cursor: pointer;
}
